import * as React from 'react';
import { Heading } from '../ui/text/Heading';
import { Accordion, AccordionTab } from '../ui/accordion/Accordion';
import { useAccordion } from '../../utils/hooks/useAccordion';
import { Button } from '../ui/button/Button';
import { Paragraph } from '../ui/text/Paragraph';
import { ButtonGroup } from '../ui/button/ButtonGroup';
import { SiteContext } from '../../utils/context/SiteContext';

export const AccordionBenefits: React.FC = () => {
  const { open, onTabClick } = useAccordion();
  const { routeConfig } = React.useContext(SiteContext);
  return (
    <>
      <Heading tag="h2" center={true}>
        Fordeler med PrivatMegleren
      </Heading>
      <Accordion>
        <AccordionTab
          open={open === 1}
          tabOnClick={() => onTabClick(1)}
          heading="Kjøpsmegling"
        >
          <Paragraph>Hjelp til kjøp av eiendom</Paragraph>
          <ButtonGroup className="row">
            <Button type="tertiary" as="a" href={routeConfig?.kjop.url}>
              Les mer
            </Button>
          </ButtonGroup>
        </AccordionTab>
        {/*         <AccordionTab
          open={open === 2}
          tabOnClick={() => onTabClick(2)}
          heading="KLiKK"
        >
          <Paragraph>
            Skreddersydd annonsering tilpasset mottaker, basert på kunstig
            intelligens og maskinlæring.
          </Paragraph>
          <ButtonGroup className="row">
            <Button type="tertiary" as="a" href={routeConfig?.klikk.url}>
              Les mer
            </Button>
          </ButtonGroup>
        </AccordionTab> */}
        <AccordionTab
          open={open === 3}
          tabOnClick={() => onTabClick(3)}
          heading="Boligbytte"
        >
          <Paragraph>
            PrivatMegleren Boligbytte sørger for at flyttingen håndteres av
            profesjonelle, slik at du beholder livskvaliteten og får trygghet og
            en bedre opplevelse når du skal flytte.
          </Paragraph>
          <ButtonGroup className="row">
            <Button type="tertiary" as="a" href={routeConfig?.boligbytte.url}>
              Les mer
            </Button>
          </ButtonGroup>
        </AccordionTab>
        <AccordionTab
          open={open === 4}
          tabOnClick={() => onTabClick(4)}
          heading="Kvadrat"
        >
          <Paragraph>
            PrivatMegleren Kvadrat gir deg overblikket og kontrollen fra vi
            setter i gang, og er din personlige assistent hele veien til solgt.
            Tjenesten gir deg informasjon i sanntid om interessenter, antall
            påmeldte til visning, budgivere, og du kan følge budrunden direkte.
            Gjennom Kvadrat vil du se hvordan vi jobber og hvorfor vår
            skreddersøm sikrer at vi oppnår det beste resultatet på alle
            eiendommene vi omsetter.
          </Paragraph>
          <ButtonGroup className="row">
            <Button type="tertiary" as="a" href={routeConfig?.kvadrat.url}>
              Les mer
            </Button>
          </ButtonGroup>
        </AccordionTab>
        {/*         <AccordionTab
          open={open === 4}
          tabOnClick={() => onTabClick(4)}
          heading="Mitt Boligsalg"
        >
          <Paragraph>
            Mitt Boligsalg er en app som tar deg med gjennom hvert lille steg i
            forbindelse med salg av din eiendom. Salgsprosessen blir synligjort
            fra første kontakt med PrivatMegleren- til din eiendom er solgt.
          </Paragraph>
          <ButtonGroup className="row">
            <Button
              type="tertiary"
              as="a"
              href={routeConfig?.mittboligsalg.url}
            >
              Les mer
            </Button>
          </ButtonGroup>
        </AccordionTab> */}
      </Accordion>
    </>
  );
};
