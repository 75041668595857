import * as React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import VisibilitySensor from 'react-visibility-sensor';

import { Heading } from '../../../ui/text/Heading';
import { Button } from '../../../ui/button/Button';
import { ButtonGroup } from '../../../ui/button/ButtonGroup';
import { useProgressiveImage } from '../../../ui/slideshow/hooks/useProgressiveImage';

type SectionType =
  | 'spoross'
  | 'hvaerdinboligverdt'
  | 'raadgivning'
  | 'denutlosendefaktor'
  | 'boligbytte'
  | 'kjop'
  | 'notis'
  | 'settprispaa'
  | 'pmverdi'
  | 'boligsok'
  | 'digitaltmøte'
  | 'boligreise'
  | 'presisjon'
  | 'kvadrat'
  | 'veientilsolgt'
  | 'detaljenesomselger'
  | 'presisjon2'
  | 'forstegangsselgeren';

interface ISection {
  direction: 'left' | 'right';
  type: SectionType;
  title?: string;
  bodyText?: string;
  content?: any;
  buttonText?: string;
  href?: string;
  to?: string;
  bg: string;
  bgMobile?: string;
}

export const Section: React.FC<ISection> = React.memo(
  ({
    direction,
    type,
    title,
    bodyText,
    buttonText,
    content,
    href,
    to,
    bg,
    bgMobile
  }) => {
    const [visible, setVisible] = React.useState(false);

    const animatedProps = useSpring({
      from: { opacity: 0, transform: 'translate3d(0, -30px, 0)' },
      to: {
        opacity: visible ? 1 : 0,
        transform: visible
          ? 'translate3d(0, 0px, 0)'
          : 'translate3d(0, -30px, 0)'
      }
    });

    const visibilityChanged = (isVisible: boolean) => {
      if (!visible && isVisible === true) {
        setVisible(true);
      }
    };

    // TODO fix span

    return (
      <VisibilitySensor
        onChange={visibilityChanged}
        partialVisibility={true}
        offset={{ bottom: 150 }}
      >
        <StyledSection style={animatedProps}>
          <StyledSectionContainer>
            <SectionImage
              imageUrl={bg}
              imageUrlMobile={bgMobile || ''}
              className={direction === 'right' ? 'align-right' : ''}
            />
            <StyledSectionContent
              className={direction === 'right' ? 'align-right' : ''}
            >
              <StyledTextContainer>
                {title && !content ? (
                  <span
                    role="heading"
                    aria-level={2}
                    style={{ color: 'white' }}
                    className="heading"
                  >
                    {title.split(' ').map((word, index) => {
                      if (!word) return null;
                      return (
                        <span
                          key={`span${index}`}
                          style={{
                            color:
                              word?.toLowerCase() === 'presisjon'
                                ? '#FF5A5A'
                                : 'white'
                          }}
                        >
                          {word}
                          {index !== title.split(' ').length - 1 && ' '}
                        </span>
                      );
                    })}
                  </span>
                ) : null}
                {bodyText && !content ? (
                  <div
                    style={{ marginBottom: '20px' }}
                    dangerouslySetInnerHTML={{ __html: bodyText }}
                  />
                ) : null}
                {content ? content : null}
                <ButtonGroup className="row center">
                  <Button
                    type="secondary"
                    as={to ? 'link' : 'a'}
                    to={to}
                    href={href}
                    onClick={(e) => {
                      e.preventDefault();
                      window.dataLayer = window.dataLayer || [];

                      if (window && window.dataLayer) {
                        window.dataLayer.push({
                          event: 'PMCardClick',
                          gaCategory: 'PMCardClick',
                          gaAction: type,
                          gaLabel: 'Frontpage'
                        });
                      }
                      if (href) {
                        window.location.href = href;
                      }
                    }}
                  >
                    {buttonText ? buttonText : 'Les mer'}
                  </Button>
                </ButtonGroup>
              </StyledTextContainer>
            </StyledSectionContent>
          </StyledSectionContainer>
        </StyledSection>
      </VisibilitySensor>
    );
  }
);

const StyledSection = styled(animated.div)`
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  padding: 0 30px;
  margin-bottom: 50px;
  float: left;

  &:last-child {
    margin-bottom: 0;
  }

  @media all and (max-width: 960px) {
    margin-bottom: 20px;
  }
`;

const StyledSectionContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  min-height: 500px;
  margin: 0 auto;

  @media all and (max-width: 960px) {
    min-height: auto;
  }
`;

const SectionImage: React.FC<{
  className: string;
  imageUrl: string;
  imageUrlMobile: string;
}> = ({ className, imageUrl, imageUrlMobile }) => {
  const { loaded } = useProgressiveImage({
    url: imageUrl
  });

  const { loaded: mobileUrlLoaded } = useProgressiveImage({
    url: imageUrlMobile
  });

  const loadedProps = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: loaded ? 1 : 0
    }
  });

  return (
    <StyledSectionImageWrapper style={loadedProps} className={className}>
      <StyledSectionImage
        alt="bilde av tjeneste"
        src={imageUrl}
        className={mobileUrlLoaded ? 'desktop ' : 'desktop no-mobile-image'}
      />
      <StyledSectionImage
        alt="bilde av tjeneste"
        src={imageUrlMobile}
        className={mobileUrlLoaded ? 'mobile' : 'mobile hide-mobile-image'}
      />
    </StyledSectionImageWrapper>
  );
};

const StyledSectionImageWrapper = styled(animated.div)`
  position: absolute;
  left: 0;
  right: 40%;
  width: 60%;
  max-width: 700px;
  height: 420px;
  margin: auto;
  background: rgb(20, 20, 20);

  &.align-right {
    right: 0;
    left: 40%;
  }

  .desktop {
    display: block;
  }

  .mobile {
    display: none;

    &.hide-mobile-image {
      display: none !important;
    }
  }

  @media all and (max-width: 960px) {
    position: relative;
    left: auto;
    right: auto;
    width: 100%;
    height: auto;

    &.align-right {
      left: auto;
      right: auto;
    }

    .desktop {
      display: none;

      &.no-mobile-image {
        display: block !important;
      }
    }

    .mobile {
      display: block;
    }
  }
`;

const StyledSectionImage = styled(animated.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media all and (max-width: 960px) {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

const StyledSectionContent = styled(animated.div)`
  position: absolute;
  top: 40px;
  left: 40%;
  right: 0;
  margin: auto;
  width: 60%;
  padding: 70px 100px;
  display: flex;
  flex-flow: column;
  max-width: 700px;
  background: rgba(33, 33, 33, 0.8);
  z-index: 2;
  height: max-content;
  min-height: 420px;

  &.align-right {
    left: 0;
    right: 40%;
  }

  .center {
    justify-content: flex-start;
  }

  @media all and (max-width: 960px) {
    position: relative;
    top: 0;
    left: auto;
    right: auto;
    width: 100%;
    height: auto;
    text-align: center;
    padding: 50px 20px;
    min-height: auto;

    &.align-right {
      left: auto;
      right: auto;
    }

    .center {
      justify-content: center;
    }
  }
`;

const StyledTextContainer = styled(animated.div)`
  display: flex;
  flex-flow: column;

  .heading {
    font-size: 2.5rem;
    font-family: 'DomaineDispCondRegular', sans-serif;
    @media all and (max-width: 349px) {
      font-size: 26px;
    }
    @media all and (min-width: 350px) and (max-width: 960px) {
      font-size: 30px;
    }
  }

  h2 {
    font-size: 40px;

    @media all and (max-width: 349px) {
      font-size: 26px;
    }
    @media all and (min-width: 350px) and (max-width: 960px) {
      font-size: 30px;
    }
  }

  p {
  }
`;
