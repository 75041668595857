import * as React from 'react';
import styled from 'styled-components';

interface IArrow {
  className: string;
}

export const ArrowIcon: React.FC<IArrow> = ({ className }) => {
  return (
    <StyledArrow className={className} tabIndex={0}>
      <svg
        width="43"
        height="26"
        viewBox="0 0 43 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.05347 1.53195L21.476 22.7344L41.0059 1.6309"
          stroke="black"
          strokeWidth="2"
        />
      </svg>
    </StyledArrow>
  );
};

const StyledArrow = styled.div``;
