import { useState, useEffect } from 'react';

interface IUseProgressiveImage {
  url: string;
  elementRef?: boolean;
  useLazyLoad?: boolean;
}

export const useProgressiveImage = ({
  url,
  elementRef,
  useLazyLoad
}: IUseProgressiveImage) => {
  const [loaded, setLoaded] = useState(false);
  const [inViewport, setInViewport] = useState(false);

  const checkLoaded = () => {
    const img = new Image();
    img.style.position = 'absolute';
    img.style.width = '1px';
    img.style.height = '1px';
    img.style.zIndex = '-1';
    img.src = url;
    img.onload = () => {
      setLoaded(true);
    };
  };

  if (useLazyLoad && !elementRef) {
    throw new Error('Need elementRef to use lazyloading');
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && url && !loaded) {
      checkLoaded();
    }
  }, [url]);

  return { loaded, inViewport };
};
