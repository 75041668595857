import { useState } from 'react';

export const useAccordion = (defaultOpen?: number) => {
  const [open, setOpen] = useState(defaultOpen ? defaultOpen : 0);
  const onTabClick = (id: number) => {
    if (id === open) {
      setOpen(0);
    } else {
      setOpen(id);
    }
  };

  return { open, onTabClick };
};
