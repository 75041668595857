import * as React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

interface IThanks {
  style?: any;
  ref?: any;
}

export const Thanks: React.FC<IThanks> = ({ style, children, ref }) => {
  return (
    <StyledThanks style={style} ref={ref}>
      {children}
    </StyledThanks>
  );
};

const StyledThanks = styled(animated.div)``;
