import * as React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

import { useFormTransition } from '../../../../utils/hooks/useFormTransition';
import { Container } from '../../../layout/Container';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { Thanks } from '../../../layout/Thanks';

import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';

import { AccordionGeneral } from '../../../partials/AccordionGeneral';
import { AccordionBenefits } from '../../../partials/AccordionBenefits';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { Seo } from '../../../../utils/seo/Index';

import { Section } from '../../home/sections/Section';
import { ValuationForm } from '../../../partials/forms/Valuation';
import { useSiteContext } from '../../../../utils/hooks/useSiteContext';
import { useValuationContext } from '../../../../utils/hooks/useValuationContext';
import { ValuationContextProvider } from '../../../../utils/context/ValuationContext';
import { PageType } from '../../../../__types__/generated/globalTypes';

const Index: React.FC = () => {
  const { routeConfig } = useSiteContext();
  const { posted, init } = useValuationContext();

  React.useEffect(() => {
    init({
      pageType: PageType.NORDEASELGEKJOPE
    });
  }, []);

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  return (
    <>
      <Seo
        title=" Skal du selge eller kjøpe eiendom?"
        description="Kom i kontakt med megler for verdifull rådgivning og bistand med kjøp og/eller salg av eiendom. Fyll ut skjemaet under og PrivatMegleren kontakter deg. Med full oversikt over eiendomsmarkedet og tilgang til eiendommer som ikke annonseres, er rådgivningen vår ved kjøp av eiendom verdifull. Med oss ved din side gjennom prosessen, sikrer du kjøp av riktig eiendom til riktig pris."
      />
      <Container className="padding-top" style={pageFadeIn}>
        <FormWrapper style={fadeOut}>
          <FormSection
            className="form-section"
            style={{ paddingBottom: '10px' }}
          >
            <Heading tag="h1" center={true}>
              Skal du selge eller kjøpe eiendom?
            </Heading>
            <Paragraph center={true} className="form-text">
              Kom i kontakt med megler for verdifull rådgivning og bistand med
              kjøp og / eller salg av eiendom. Fyll ut skjemaet under og
              PrivatMegleren kontakter deg.
            </Paragraph>
            <Paragraph center={true} className="form-text">
              Med full oversikt over eiendomsmarkedet og tilgang til eiendommer
              som ikke annonseres, er rådgivningen vår ved kjøp av eiendom
              verdifull.
            </Paragraph>
            <Paragraph
              style={{ marginBottom: '2em' }}
              center={true}
              className="form-text"
            >
              Med oss ved din side gjennom prosessen, sikrer du kjøp av riktig
              eiendom til riktig pris.
            </Paragraph>
          </FormSection>
          <ValuationForm />
        </FormWrapper>
        <Thanks style={fadeIn}>
          <FormSection className="form-section">
            <Heading tag="h1" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg så snart som mulig.
            </Paragraph>
            <Paragraph center={true}>
              I mellomtiden kan du lese mer om salg av eiendom her.
            </Paragraph>
            <AccordionGeneral />
          </FormSection>
          <FormSection className="form-section" style={{ paddingTop: 0 }}>
            <AccordionBenefits />
          </FormSection>
        </Thanks>
        <Wrapper>
          <Section
            direction="right"
            type="boligreise"
            title="Alt du lurer på – fra start til solgt"
            bodyText={`<p>I vår nye serie utfordres vi av Pia Tjelta til å avsløre hvordan du lykkes med kjøp og salg av eiendom.</p>`}
            buttonText="Les mer"
            href={routeConfig?.boligreise?.url}
            bg="https://cdn.reeltime.no/pm_assets/kampanjer/leveranse/boligreise.jpg"
          />
          <Section
            direction="left"
            type="kjop"
            title="PrivatMegleren Kjøp"
            bodyText={`<p>Nå får du også bistand ved kjøp av eiendom</p>`}
            href={routeConfig?.kjop?.url}
            bg="https://cdn.reeltime.no/pm_assets/forside/kjop.jpg"
          />
        </Wrapper>
      </Container>

      <BackgroundLoad
        opacity={0.3}
        url="https://cdn.reeltime.no/pm_assets/img/cbimage.jpg"
      />
    </>
  );
};

const SelgeKjopeBank: React.FC = (props) => {
  return (
    <ValuationContextProvider>
      <Index {...props} />
    </ValuationContextProvider>
  );
};

export default SelgeKjopeBank;

const Wrapper = styled.div`
  .right.left {
    padding: unset;
  }
  @media all and (min-width: 600px) {
    margin-top: 4em;
    margin-bottom: 4em;
  }
`;

const FormSection = styled(animated.div)`
  padding: 3.125rem 0;
  &.center {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  &.form-section {
    max-width: 550px;
    margin: 0 auto;
  }

  &.no-padding-top {
    padding: 0 0 3.125rem 0;
  }

  &.office {
    padding: 0;
  }
  &.brokerlist {
    @media all and (max-width: 600px) {
      padding: 0 0 3.125rem;
    }
  }
`;

const FormWrapper = styled(animated.div)`
  .privacy-link-mobile {
    margin-bottom: 2em;
  }
`;
