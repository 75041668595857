import * as React from 'react';
import { Heading } from '../ui/text/Heading';
import { Accordion, AccordionTab } from '../ui/accordion/Accordion';
import { useAccordion } from '../../utils/hooks/useAccordion';
import { SiteContext } from '../../utils/context/SiteContext';
import { Paragraph } from '../ui/text/Paragraph';

export const AccordionGeneral: React.FC = () => {
  const { routeConfig } = React.useContext(SiteContext);
  const { open, onTabClick } = useAccordion();
  return (
    <>
      <Accordion>
        <AccordionTab
          open={open === 1}
          tabOnClick={() => onTabClick(1)}
          headingColor="#fff"
          heading="Hva er verdivurdering?"
        >
          <Heading tag="h4">E-Takst fra Eiendomsverdi</Heading>
          <Paragraph>
            E-Takst fra Eiendomsverdi er din PrivatMeglers verdivurdering for
            bruk ved låneopptak eller refinansiering i bank. I tillegg til selve
            E-taksten på èn side, vil banken kunne se meglers konkrete
            vurdering, datagrunnlag og bilder.Dersom kjøper og selger har funnet
            hverandre direkte uten megler, og kun ønsker bistand til kontrakt og
            oppgjør, vil det samme produktet automatisk være inkludert i
            megleroppdraget.
          </Paragraph>
          <Heading tag="h4">Meglers verdivurdering" fra Eiendomsverdi</Heading>
          <Paragraph>
            Meglers Verdivurdering fra Eiendomsverdi er din PrivatMeglers
            verdivurdering for bruk i.f.m. salg eller kjøp av eiendom, og skal
            være vedlagt alle salgsoppgaver.Dokumentet på 4 sider vil gi
            potensielle kjøpere innsyn i meglers konkrete vurdering og
            datagrunnlag. Her vil en kunne se hvordan megler har kommet frem til
            verdien, hvilke sammenlignbare eiendommer som er solgt, hvilke som
            er for salg i markedet akkurat nå m.m.
          </Paragraph>
        </AccordionTab>
        <AccordionTab
          open={open === 2}
          tabOnClick={() => onTabClick(2)}
          headingColor="#fff"
          heading="Forventninger"
        >
          <Paragraph>
            Først når verdivurderingen er foretatt, kan det være aktuelt å
            utføre oppgraderinger som kan påvirke verdien.Som eiendomsselger får
            du raskt et innblikk i hva markedet kan være villig til å betale, og
            dermed avstemme omdette harmonerer med dine egne forventninger.
          </Paragraph>
        </AccordionTab>
        <AccordionTab
          open={open === 3}
          tabOnClick={() => onTabClick(3)}
          headingColor="#fff"
          heading="Vår tilnærming"
        >
          <Paragraph>
            Når vi verdivurderer din eiendom, skjer det gjennom en befaring
            sammen med deg som selger. Du viser frem eiendommen, presenterer de
            ulike rommene og forklarer hva som eventuelt er utført av
            oppgraderinger siden duflyttet inn. I tillegg til befaringen vil
            megleren i forkant innhente statistisk informasjon om eiendomspriser
            i området, og naturligvis ta hensyn til utviklingen i markedet.
          </Paragraph>
        </AccordionTab>
        <AccordionTab
          open={open === 4}
          tabOnClick={() => onTabClick(4)}
          headingColor="#fff"
          heading="Faktorer i verdivurderingen"
        >
          <Paragraph>
            Det er flere faktorer som gjenspeiles i en verdivurdering – den
            representerer summen av totaliteten. I detteligger beliggenhet,
            standard på eiendommen, antall rom og planløsninger, nabolag, nærhet
            til skoler, barnehager ogaktivitetstilbud, kollektivtilbud, garasje-
            og parkeringsmuligheter, uteområder og eventuelle fellesarealer, for
            ånevne noe.
          </Paragraph>
        </AccordionTab>
        <AccordionTab
          open={open === 5}
          tabOnClick={() => onTabClick(5)}
          headingColor="#fff"
          heading="Rådgivende rolle"
        >
          <Paragraph>
            I forbindelse med befaringen har megleren også en rådgivende rolle
            som skal fremheve særegenheter ved nettopp din eiendom, og hva du
            eventuelt kan gjøre for at eiendommen blir enda mer attraktiv hos
            potensielle kjøpere.Vår klare anbefaling er at du ikke setter i gang
            arbeid på eiendommen før du har gjennomført befaringen medmegler.
            Basert på erfaring er det i de fleste tilfeller bare mindre
            oppgraderinger som skal til for å få eiendommen til å fremstå som
            den fortjener.
          </Paragraph>
        </AccordionTab>
      </Accordion>
    </>
  );
};
