import * as React from 'react';
import styled, { css } from 'styled-components';
import { ArrowIcon } from '../icons/Arrow';

interface IAccordionTab {
  heading: string;
  open: boolean;
  headingColor?: string;
  tabOnClick?: () => void;
  className?: string;
}

export const Accordion: React.FC = ({ children }) => {
  return <StyledAccordionWrapper>{children}</StyledAccordionWrapper>;
};

export const AccordionTab: React.FC<IAccordionTab> = React.memo(
  ({ heading, className, open, children, headingColor, tabOnClick }) => {
    return (
      <StyledAccordionTab>
        <StyledAccordionTabHeading
          className={className}
          open={open}
          onClick={tabOnClick}
        >
          <span style={{ color: headingColor }}>{heading}</span>
          <ArrowIcon className={`accordion-arrow ${open ? 'open' : ''}`} />
        </StyledAccordionTabHeading>
        {open ? (
          <StyledAccordionTabContent>{children}</StyledAccordionTabContent>
        ) : null}
      </StyledAccordionTab>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.open === nextProps.open) {
      return true;
    }
    return false;
  }
);

const StyledAccordionWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
`;

const StyledAccordionTab = styled.div``;

const StyledAccordionTabHeading: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-size: 1em;
  color: ${(props) => props.theme.colors.primary};
  border-top: solid 1px rgb(40, 40, 40);
  user-select: none;
  ${(props: any) =>
    props.open ? 'border-bottom: solid 1px rgb(40, 40, 40);' : ''}

  &:nth-of-type(even) {
    border-bottom: solid 1px rgb(40, 40, 40);
  }

  &:hover {
    cursor: pointer;
  }

  .accordion-arrow {
    transition: transform 0.2s ease-in-out;
    width: 20px;
    height: 20px;
    transform: rotateX(0deg);
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: ${(props) => props.theme.colors.primary};
        stroke-width: 3px;
      }
    }

    &.open {
      transform: rotateX(180deg);
    }
  }
`;

const StyledAccordionTabContent = styled.div`
  padding: 1.25rem 1.25rem 2.5rem 1.25rem;
  color: white;
`;
